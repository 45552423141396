export default (data = {}) => {
    return {
        account: null,
        code: null,
        group_name: null,
        subgroup_name: null,
        type_id: null,
        identification_id: null,
        category: null,
        name: null,
        address: null,
        address2: null,
        city: null,
        province: null,
        postal_code: null,
        mobile: null,
        email: null,
        shipping_name: null,
        shipping_address: null,
        shipping_address2: null,
        shipping_postal_code: null,
        shipping_province: null,
        shipping_city: null,
        additional_accoun_id: null,
        tax_level_code: null,
        picture: null,
        issue_date: null,
        location: null,
        referred_by: null,
        status: null,
        facebook: null,
        instagram: null,
        qr: null,
        agent: null,
        menus: null,
        password: null,
        setting: {
            menutype: true,
            whitPhoto: true,
            font: "Roboto",
            bgcolor: "white",
          },
        ...data
    }
}




