import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '../views/Payroll/Login.vue'
import store from "../store"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '/',
        component: Welcome,
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Login',
        name: 'Login',
        component: () =>
            import('../views/Payroll/Login.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Welcome',
        name: 'Welcome',
        component: () =>
            import('../views/Payroll/Welcome.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Clients',
        name: 'Clients',
        props: true,
        component: () =>
            import('../views/Payroll/Clients.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/ServicesSchedule',
        name: 'ServicesSchedule',
        component: () =>
            import('../views/Payroll/Services_schedule.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/ClientsMap',
        name: 'ClientsMap',
        component: () =>
            import('../views/Payroll/ClientsMap.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Employees',
        name: 'Employees',
        props: true,
        component: () =>
            import('../views/Payroll/Employees.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Users',
        name: 'Users',
        props: true,
        component: () =>
            import('../views/Payroll/Users.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/mypeople',
        name: 'mypeople',
        component: () =>
            import('../views/Payroll/TrackerMap.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Supplier',
        name: 'Supplier',
        component: () =>
            import('../views/Payroll/Supplier.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/MyTasks',
        name: 'MyTasks',
        component: () =>
            import('../views/Employees/MyTasks.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Client',
        name: 'Client',
        component: () =>
            import('../views/Employees/Client.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Services',
        name: 'Services',
        props: true,
        component: () =>
            import('../views/Payroll/Catalogue.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },


    {
        path: '/MyStore',
        name: 'MyStore',
        component: () =>
            import('../views/Store.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/cc_dashboard',
        name: 'cc_dashboard',
        component: () =>
            import('../views/CallCenter/Dashboard.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Inicio',
        name: 'Inicio',
        component: () =>
            import('../views/Clients/Welcome.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Cart',
        name: 'Cart',
        component: () =>
            import('../views/Clients/Cart.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/MyOrders',
        name: 'MyOrders',
        component: () =>
            import('../views/Clients/Orders.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Marketing',
        name: 'Marketing',
        component: () =>
            import('../views/Marketing.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Checkout',
        name: 'Checkout',
        component: () =>
            import('../views/Checkout.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Registration',
        name: 'Registration',
        component: () =>
            import('../views/Registration.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Ingreso',
        name: 'Ingreso',
        component: () =>
            import('../views/Clients/Login.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Sales_stores',
        name: 'Sales_stores',
        component: () =>
            import('../views/sales/Stores.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/QR4',
        name: 'QR4',
        component: () =>
            import('../views/QR4.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Cash_report',
        name: 'Cash_report',
        component: () =>
            import('../views/Cash_report.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/BusinessCard',
        name: 'BusinessCard',
        component: () =>
            import('../views/BusinessCard.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/registration',
        name: 'registration',
        component: () =>
            import('../views/Contact.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/WelcomeChef',
        name: 'WelcomeChef',
        component: () =>
            import('../views/chef/Welcome.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_plates',
        name: 'Chef_plates',
        component: () =>
            import('../views/chef/Chef_plates.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_Supplier',
        name: 'Chef_Supplier',
        component: () =>
            import('../views/chef/Chef_Supplier.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_Store',
        name: 'Chef_Store',
        component: () =>
            import('../views/chef/Chef_Store.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_Supplier_Catalogue',
        name: 'Chef_Supplier_Catalogue',
        component: () =>
            import('../views/chef/Chef_Supplier_Catalogue.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_cost_analysis',
        name: 'Chef_cost_analysis',
        component: () =>
            import('../views/chef/Chef_cost_analysis.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_orders',
        name: 'Chef_orders',
        component: () =>
            import('../views/chef/Chef_orders.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_delivery',
        name: 'Chef_delivery',
        component: () =>
            import('../views/chef/Chef_delivery.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },

    {
        path: '/StoresMap',
        name: 'StoresMap',
        component: () =>
            import('../views/StoresMap.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },

    {
        path: '/DashBoard',
        name: 'DashBoard',
        component: () =>
            import('../views/DashBoard.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Receipt_in',
        name: 'Receipt_in',
        component: () =>
            import('../views/Receipt_in.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Receipt_out',
        name: 'Receipt_out',
        component: () =>
            import('../views/Receipt_out.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },

    {
        path: '/MasterList',
        name: 'MasterList',
        component: () =>
            import('../views/Master.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Inventario',
        name: 'Inventory',
        component: () =>
            import('../views/Inventory.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/About',
        name: 'About',
        component: () =>
            import('../views/About.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },


    {
        path: '/Vendedores',
        name: 'Vendedores',
        props: true,
        component: () =>
            import('../views/Vendors.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },

    {
        path: '/Costs',
        name: 'Costs',
        component: () =>
            import('../views/Cost_analysis.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Pos',
        name: 'Pos',
        props: true,
        component: () =>
            import('../views/Stores.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/chef_tpv',
        name: 'chef_tpv',
        props: true,
        component: () =>
            import('../views/chef/Chef_tpv.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/kitchen',
        name: 'kitchen',
        props: true,
        component: () =>
            import('../views/chef/Kitchen.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },

    {
        path: '/Productos',
        name: 'Productos',
        props: true,
        component: () =>
            import('../views/Products.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Catalogo',
        name: 'Catalogo',
        props: true,
        component: () =>
            import('../views/Catalogue.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Material',
        name: 'Material',
        props: true,
        component: () =>
            import('../views/Raw_Material.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Material_prices',
        name: 'Material_prices',
        props: true,
        component: () =>
            import('../views/Raw_Material_prices.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Material_supplier',
        name: 'Material_supplier',
        props: true,
        component: () =>
            import('../views/Raw_Material_supplier.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Orders',
        name: 'Orders',
        props: true,
        component: () =>
            import('../views/Orders.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },

    {
        path: '/Doc_e',
        name: 'Doc_e',
        props: true,
        component: () =>
            import('../views/Doc_e.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/docs',
        name: 'docs',
        props: true,
        component: () =>
            import('../views/Docs.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Compras',
        name: 'Compras',
        props: true,
        component: () =>
            import('../views/Stock_order.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Calculadora',
        name: 'Calculadora',
        props: true,
        component: () =>
            import('../views/Trout_calculator.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/TroutFeed',
        name: 'TroutFeed',
        props: true,
        component: () =>
            import('../views/Trout_feed.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Settings',
        name: 'Settings',
        props: true,
        component: () =>
            import('../views/Settings.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/FishEvents',
        name: 'FishEvents',
        props: true,
        component: () =>
            import('../views/fish/Fish_events.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    duplicateNavigationPolicy: 'reload', // other options: 'ignore' and 'reject'
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.profile) {
            if (store.state.profile.code) {
                next()
            } else {
                next({
                    name: '/',
                    params: { nextUrl: to.fullPath }
                })
            }
            return
        } else {
            next({
                name: '/',
                params: { nextUrl: to.fullPath }
            })
        }
    } else {
        next()
    }
})

export default router